<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Wolks

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />
            <v-btn
              href="https://www.wolkairbag.com/order"
              color="primary"
              target="_blank"
              class="mx-2"
            >
              Order plaatsen
            </v-btn>

            <v-dialog
              v-if="showSendBtn"
              v-model="dialog"
              persistent
              max-width="1000px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  :disabled="false"
                  color="primary"
                  v-on="on"
                >
                  verzenden
                </v-btn>
              </template>
              <!-- template for selecting wolks -->
              <v-card
                v-if="dialogScreen === 'selectWolks'"
              >
                <v-card-title>
                  <span
                    class="text-h6"
                  >
                    Wolks verzenden
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    v-model="selected"
                    :headers="retourHeaders"
                    :options.sync="retourTableSort"
                    :items="retourWolks"
                    :items-per-page="20"
                    :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
                    :loading="loading"
                    show-select
                    item-key="metaData.sleevePublicId"
                  >
                    <template #[`item.metaData.sleevePublicId`]="{ item }">
                      <router-link :to="{ name: 'wolksShow', params: { id: item._id } }">
                        {{ item.metaData.sleevePublicId }}
                      </router-link>
                    </template>

                    <template #[`item.metaData.organizationTitle`]="{ item }">
                      <router-link
                        v-if="item.metaData &&
                          item.organizationId &&
                          item.metaData.organizationTitle"
                        :to="{ name: 'organizationsShow', params: { id: item.organizationId } }"
                      >
                        {{ item.metaData.organizationTitle }}
                      </router-link>
                    </template>

                    <template #[`item.metaData.locationTitle`]="{ item }">
                      <router-link
                        v-if="item.metaData && item.metaData.locationTitle"
                        :to="{ name: 'locationsShow', params: { id: item.locationId } }"
                      >
                        {{ item.metaData.locationTitle }}
                      </router-link>
                    </template>

                    <template #[`item.metaData.size`]="{ item }">
                      {{ item.metaData.size }}
                    </template>
                    <template #[`item.retour.status`]="{ item }">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ getIconForRetourStatus(item.retour) }}
                          </v-icon>
                        </template>
                        <span>{{ getHumanizedRetourStatus(item.retour) }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="closeDialog"
                  >
                    Terug
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    :disabled="!selected.length > 0"
                    @click="getNextDialogScreen"
                  >
                    Doorgaan
                  </v-btn>
                </v-card-actions>
              </v-card>
              <!-- template for send-->
              <v-card
                v-if="dialogScreen === 'send'"
              >
                <v-card-title>
                  Adresgegevens afzender
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="sendForm"
                    autocomplete="on"
                  >
                    <v-text-field
                      v-model="fromStreet"
                      label="Straat"
                      :rules="[rules.required]"
                      autocomplete="street-address"
                    />
                    <v-text-field
                      v-model="fromHouseNumber"
                      label="Huisnummer"
                      :rules="[rules.required, rules.houseNumber]"
                      autocomplete="address-line2"
                    />
                    <v-text-field
                      v-model="fromCity"
                      label="Stad"
                      :rules="[rules.required]"
                      autocomplete="address-level2"
                    />
                    <v-text-field
                      v-model="fromPostalCode"
                      label="Postcode"
                      :rules="[rules.required, rules.postCode]"
                      autocomplete="postal-code"
                    />
                    <v-select
                      v-model="fromCountry"
                      :items="countries"
                      item-text="country"
                      item-value="abbr"
                      label="Land"
                      :rules="[rules.required]"
                      autocomplete="country"
                    />
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="closeDialog"
                  >
                    Annuleren
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    :disabled="!fromStreet
                      || !fromHouseNumber
                      || !fromCity
                      || !fromPostalCode
                      || !fromCountry"
                    @click="getNextDialogScreen"
                  >
                    Doorgaan
                  </v-btn>
                </v-card-actions>
              </v-card>
              <!-- template for sendOverview-->
              <v-card
                v-if="dialogScreen === 'sendOverview'"
              >
                <v-card-title>
                  Overzicht verzending
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="4"
                    >
                      <p class="font-weight-bold">
                        Ontvanger:
                      </p>
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <p class="pl-4 font-weight-medium">
                        {{ receiver }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="4"
                    >
                      <p class="font-weight-bold">
                        Adres ontvanger:
                      </p>
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <p class="pl-4 font-weight-medium">
                        {{ receiverAddress }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="4"
                    >
                      <p class="font-weight-bold">
                        Adres afzender:
                      </p>
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <p class="pl-4 font-weight-medium">
                        {{
                          `${fromStreet} ${fromHouseNumber},
                      ${fromCity} ${fromPostalCode} ${fromCountry}`
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="lastName && lastName.length > 0 && firstName && firstName.length > 0"
                  >
                    <v-col
                      cols="4"
                    >
                      <p class="font-weight-bold">
                        Aanvrager:
                      </p>
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <p class="pl-4 font-weight-medium">
                        {{ `${lastName} ${firstName}` }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="email && email.length > 0"
                  >
                    <v-col
                      cols="4"
                    >
                      <p class="font-weight-bold">
                        Email adres:
                      </p>
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <p class="pl-4 font-weight-medium">
                        {{ email }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p class="font-weight-bold">
                        Geselecteerde wolks:
                      </p>
                    </v-col>
                  </v-row>
                  <v-data-table
                    v-model="selected"
                    class="mt-4"
                    :headers="retourHeaders"
                    :options.sync="retourTableSort"
                    :items="selected"
                    :items-per-page="20"
                    :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
                    :loading="loading"
                    item-key="metaData.sleevePublicId"
                  >
                    <template #[`item.metaData.sleevePublicId`]="{ item }">
                      <router-link :to="{ name: 'wolksShow', params: { id: item._id } }">
                        {{ item.metaData.sleevePublicId }}
                      </router-link>
                    </template>

                    <template #[`item.metaData.organizationTitle`]="{ item }">
                      <router-link
                        v-if="item.metaData &&
                          item.organizationId &&
                          item.metaData.organizationTitle"
                      >
                        {{ item.metaData.organizationTitle }}
                      </router-link>
                    </template>

                    <template #[`item.metaData.locationTitle`]="{ item }">
                      <router-link
                        v-if="item.metaData && item.metaData.locationTitle"
                        :to="{ name: 'locationsShow', params: { id: item.locationId } }"
                      >
                        {{ item.metaData.locationTitle }}
                      </router-link>
                    </template>

                    <template #[`item.metaData.size`]="{ item }">
                      {{ item.metaData.size }}
                    </template>
                    <template #[`item.retour.status`]="{ item }">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ getIconForRetourStatus(item.retour) }}
                          </v-icon>
                        </template>
                        <span>{{ getHumanizedRetourStatus(item.retour) }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                  <v-row>
                    <v-col>
                      <h3>Je ontvangt het retourlabel per mail</h3>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    :loading="loadingRetour"
                    @click="closeDialog"
                  >
                    Annuleren
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    :loading="loadingRetour"
                    @click="sendWolks"
                  >
                    Bevestigen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :options.sync="tableSort"
            :items="filteredWolks"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            :loading="loading"
          >
            <template #[`item.metaData.sleevePublicId`]="{ item }">
              <router-link :to="{ name: 'wolksShow', params: { id: item._id } }">
                {{ item.metaData.sleevePublicId }}
              </router-link>
            </template>

            <template #[`item.metaData.organizationTitle`]="{ item }">
              <router-link
                v-if="item.metaData && item.organizationId && item.metaData.organizationTitle"
                :to="{ name: 'organizationsShow', params: { id: item.organizationId } }"
              >
                {{ item.metaData.organizationTitle }}
              </router-link>
            </template>

            <template #[`item.metaData.locationTitle`]="{ item }">
              <router-link
                v-if="item.metaData && item.metaData.locationTitle"
                :to="{ name: 'locationsShow', params: { id: item.locationId } }"
              >
                {{ item.metaData.locationTitle }}
              </router-link>
            </template>

            <template #[`item.metaData.size`]="{ item }">
              {{ item.metaData.size }}
            </template>

            <template #[`item.retour.status`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ getIconForRetourStatus(item.retour) }}
                  </v-icon>
                </template>
                <span>{{ getHumanizedRetourStatus(item.retour) }}</span>
              </v-tooltip>
            </template>

            <template #[`item.currentStatusInfo.batteryPercentage`]="{ item }">
              <v-chip
                :color="getHumanizedBatteryPercentage(item).color"
              >
                {{ getHumanizedBatteryPercentage(item).name }}
              </v-chip>
            </template>

            <template #[`item.currentStatusInfo.firmwareVersion`]="{ item }">
              <firmware-version-chip :status-info="item.currentStatusInfo || {}" />
            </template>

            <template #[`item.currentStatusInfo.parsedStatusKey`]="{ item }">
              <v-chip
                :color="getStatusInfo(item.currentStatus).color"
                :text-color="getStatusInfo(item.currentStatus).inversTextColor ? 'white' : 'black'"
              >
                {{ getStatusInfo(item.currentStatus).title }}
              </v-chip>
            </template>

            <template #[`item.currentStatusInfo.createdAt`]="{ item }">
              {{
                item.currentStatusInfo ?
                  getHumanizedDate(item.currentStatusInfo.createdAt) :
                  '--'
              }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import FirmwareVersionChip from '../components/FirmwareVersionChip.vue';
import WolkPrivateDataAlert from '../components/WolkPrivateDataAlert.vue';

import {
  createBreadcrumbs,
  getHumanizedBatteryPercentage,
  getHumanizedDate,
  getStatusInfo,
} from '../helpers/humanizer';

export default {
  components: {
    FirmwareVersionChip,
    WolkPrivateDataAlert,
  },
  data() {
    return {
      filter: '',
      loading: false,
      wolks: [],
      tableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['currentStatusInfo.createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      // retour data
      dialog: false,
      dialogScreen: 'selectWolks',
      showSendBtn: false,
      selected: [],
      retourTableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['metaData.locationTitle'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      firstName: null,
      lastName: null,
      email: null,
      sendAddress: null,
      receiver: 'Wolk B.V.',
      receiverAddress: 'Anna van Hannoverstraat 4, 2595 BJ Den Haag, Nederland',
      loadingRetour: false,
      rules: {
        required: (value) => !!value || 'Verplicht',
        number: (v) => Number.isInteger(v) || 'Moet numeriek zijn',
        postCode: (v) => /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || 'Geen geldige postcode',
        houseNumber: (v) => /^.{0,10}$/.test(v) || 'Maximaal aantal karakters of cijfers is 10',
      },
      countries: [
        {
          country: 'Nederland',
          abbr: 'NL',
        },
      ],
      fromStreet: null,
      fromHouseNumber: null,
      fromCity: null,
      fromPostalCode: null,
      fromCountry: 'NL',
    };
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Wolks',
            disabled: true,
            exact: true,
            to: { name: 'wolks' },
          },
        ],
        false,
      );
    },
    retourHeaders() {
      return [
        { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
        { text: 'ID', value: 'customerIdentifier', sortable: false },
        { text: 'Locatie', value: 'metaData.locationTitle', sortable: true },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Maat', value: 'metaData.size', sortable: false },
        { text: 'Retour', value: 'retour.status', sortable: true },
      ];
    },
    headers() {
      if (this.$store.state.auth.type === 'admin') {
        return [
          { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
          { text: 'ID', value: 'customerIdentifier', sortable: false },
          { text: 'Organisatie', value: 'metaData.organizationTitle', sortable: false },
          { text: 'Locatie', value: 'metaData.locationTitle', sortable: false },
          { text: 'Type', value: 'type', sortable: true },
          { text: 'Maat', value: 'metaData.size' },
          { text: 'Retour', value: 'retour.status' },
          { text: 'Status', value: 'currentStatusInfo.parsedStatusKey', sortable: false },
          { text: 'Batterij', value: 'currentStatusInfo.batteryPercentage', sortable: false },
          { text: 'Software', value: 'currentStatusInfo.firmwareVersion' },
          { text: 'Laatst online', value: 'currentStatusInfo.createdAt' },
        ];
      }

      const [anyRetourEnabledOrg] = [...new Set(this.wolks.map((w) => w.retourEnabled))];
      if (anyRetourEnabledOrg) {
        return [
          { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
          { text: 'ID', value: 'customerIdentifier', sortable: false },
          { text: 'Locatie', value: 'metaData.locationTitle', sortable: false },
          { text: 'Type', value: 'type', sortable: true },
          { text: 'Maat', value: 'metaData.size' },
          { text: 'Retour', value: 'retour.status' },
          { text: 'Status', value: 'currentStatusInfo.parsedStatusKey', sortable: false },
          { text: 'Batterij', value: 'currentStatusInfo.batteryPercentage', sortable: false },
          { text: 'Software', value: 'currentStatusInfo.firmwareVersion' },
          { text: 'Laatst online', value: 'currentStatusInfo.createdAt' },
        ];
      }

      return [
        { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
        { text: 'ID', value: 'customerIdentifier', sortable: false },
        { text: 'Locatie', value: 'metaData.locationTitle', sortable: false },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'Maat', value: 'metaData.size' },
        { text: 'Status', value: 'currentStatusInfo.parsedStatusKey', sortable: false },
        { text: 'Batterij', value: 'currentStatusInfo.batteryPercentage', sortable: false },
        { text: 'Software', value: 'currentStatusInfo.firmwareVersion' },
        { text: 'Laatst online', value: 'currentStatusInfo.createdAt' },
      ];
    },
    applicableFilter() {
      let lowerCaseFilter = this.filter.toLowerCase();

      const onlyOnlineRegex = /\s*online\s*/gi;
      const onlyOnlineFound = onlyOnlineRegex.test(lowerCaseFilter);

      if (onlyOnlineFound) {
        lowerCaseFilter = lowerCaseFilter.replace(onlyOnlineRegex, '');
      }

      const invalidAcummulatedRegex = /\s*invalid accumulated\s*/gi;
      const invalidAcummulatedFound = invalidAcummulatedRegex.test(lowerCaseFilter);

      if (invalidAcummulatedFound) {
        lowerCaseFilter = lowerCaseFilter.replace(invalidAcummulatedRegex, '');
      }

      return {
        onlyOnline: onlyOnlineFound,
        invalidAcummulated: invalidAcummulatedFound,
        string: lowerCaseFilter,
      };
    },
    retourWolks() {
      return this.wolks.filter((w) => w.retour && (w.retour.status === 'exchange' || w.retour.status === 'retour') && w.retourEnabled);
    },
    selectedRetourWolks() {
      return this.selected;
    },
    filteredWolks() {
      const stringFilter = (w) => {
        if (w.metaData.sleevePublicId.toLowerCase().indexOf(this.applicableFilter.string) === 0) {
          return true;
        }

        if (
          w.customerIdentifier
          && w.customerIdentifier.toLowerCase().includes(this.applicableFilter.string)
        ) {
          return true;
        }

        if (
          w.metaData.organizationTitle
          && w.metaData.organizationTitle.toLowerCase().includes(this.applicableFilter.string)
        ) {
          return true;
        }

        if (w.metaData.locationTitle && w.metaData.locationTitle.toLowerCase()
          .includes(this.applicableFilter.string)) {
          return true;
        }
        return false;
      };

      if (!this.filter) {
        return this.wolks;
      }

      if (this.applicableFilter.onlyOnline && this.applicableFilter.invalidAcummulated) {
        return this
          .wolks
          .filter((w) => w.online)
          .filter((w) => !w.accumulatedIsValid)
          .filter(stringFilter);
      }

      if (this.applicableFilter.invalidAcummulated) {
        return this
          .wolks
          .filter((w) => !w.accumulatedIsValid)
          .filter(stringFilter);
      }

      if (this.applicableFilter.onlyOnline) {
        return this
          .wolks
          .filter((w) => w.online)
          .filter(stringFilter);
      }

      return this
        .wolks
        .filter(stringFilter);
    },
  },
  beforeMount() {
    this.loadWolks();
  },
  methods: {
    getHumanizedRetourStatus(retour) {
      const status = retour?.status;
      const retourReason = retour?.reason;

      if (status === 'exchange') {
        if (retourReason === 'maintenance') {
          return 'onderhoud - omruilen';
        }
        return 'omruilen';
      }
      if (status === 'sent') {
        return 'verzonden';
      }
      if (status === 'maintenance') {
        return 'onderhoud';
      }
      if (status === 'retour') {
        return 'retour';
      }
      return '';
    },
    getIconForRetourStatus(retour) {
      const retourStatus = retour?.status;
      const retourReason = retour?.reason;

      if (retourStatus === 'sent') {
        return 'mdi-email-fast-outline';
      }
      if (retourStatus === 'maintenance') {
        return 'mdi-wrench';
      }
      if (retourStatus === 'exchange') {
        if (retourReason === 'maintenance') {
          return 'mdi-wrench';
        }
        return 'mdi-swap-horizontal';
      }
      if (retourStatus === 'retour') {
        return 'mdi-arrow-right';
      }
      return '';
    },
    getNextDialogScreen() {
      if (this.dialogScreen === 'selectWolks') {
        this.dialogScreen = 'send';
        return;
      }
      if (this.dialogScreen === 'send') {
        if (this.$refs.sendForm.validate()) {
          this.dialogScreen = 'sendOverview';
        }
      }
    },
    async sendWolks() {
      this.loadingRetour = true;
      const selectedWolks = this.selected;
      const wolkIds = selectedWolks.map((w) => w._id);

      const retours = [];
      for (let i = 0; i < selectedWolks.length; i += 1) {
        const selectedWolk = selectedWolks[i];
        const selectedId = selectedWolk._id;

        const retour = {
          timestamp: Date.now(),
          status: 'sent',
          user: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            organizationId: this.organizationId,
          },
          fromAddress: {
            from_address_1: this.fromStreet,
            from_house_number: this.fromHouseNumber,
            from_city: this.fromCity,
            from_postal_code: this.fromPostalCode,
            from_country: this.fromCountry,
          },
        };

        selectedWolk.retour = retour;

        retours.push({
          wolkId: selectedId,
          retour,
        });
      }
      this.setRetourVars();
      this.dialog = false;
      let errorOccured = false;
      await this.patchWolkRetours({ retours });
      await this.createRetourParcel(wolkIds).then(() => {
        this.loadWolks();
      }).catch(async () => {
        // Error in order system => revert status
        await this.revertRetourStatus(retours);
        this.$store.commit('app/snackbarText', {
          text: 'Aanmaken label is mislukt',
          visible: true,
        });
        this.$store.commit('app/snackbarSuccess', false);
        errorOccured = true;
        this.loadingRetour = true;
      });
      if (!errorOccured) {
        await this.downloadLabelPdf(wolkIds[0]).then((res) => {
          // Download the label pdf automatically
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'label.pdf';
          link.click();
          URL.revokeObjectURL(link.href);

          this.$store.commit('app/snackbarSuccess', {
            text: 'Retour label gedownload en verstuurd via mail.',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', true);
        }).catch(async () => {
          // Error => revert status
          await this.revertRetourStatus(retours);
          await this.cancelParcel(wolkIds[0]);
          this.$store.commit('app/snackbarText', {
            text: 'Downloaden label is mislukt',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
          errorOccured = true;
          this.loadingRetour = true;
        });
      }

      if (!errorOccured) {
        await this.sendMultipleToOrderSystem(wolkIds).catch(async () => {
          // Error in order system => revert status
          await this.cancelParcel(wolkIds[0]);
          await this.revertRetourStatus(retours);

          this.$store.commit('app/snackbarText', {
            text: 'Aanmaken label is mislukt',
            visible: true,
          });
          this.$store.commit('app/snackbarSuccess', false);
        });
        this.loadingRetour = true;
      }
      this.loadingRetour = true;
    },
    async revertRetourStatus(retours) {
      const revertedRetours = retours.map((r) => ({
        ...r,
        retour: {
          ...r.retour,
          status: r.retourDescription ? 'retour' : 'exchange',
        },
      }));
      await this.patchWolkRetours({ retours: revertedRetours });
      await this.loadWolks();
    },
    cancelParcel(wolkId) {
      return this.cancelParcels([wolkId]);
    },
    cancelParcels(wolkIds) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/parcel/cancel`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
        data: wolkIds,
      });
    },
    sendMultipleToOrderSystem(wolkIds) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/retour/order`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
        data: { wolkIds },
      });
    },
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    getHumanizedBatteryPercentage(wolk) {
      return getHumanizedBatteryPercentage(wolk, true);
    },
    getStatusInfo(key) {
      return getStatusInfo(key);
    },
    loadWolks() {
      const onlineWolkCutOff = new Date().getTime() - (1000 * 60 * 10);
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/wolks`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.wolks = response.data.wolks.map((w) => ({
            ...w,
            online: w.currentStatusInfo
              && w.currentStatusInfo.createdAt
              && w.currentStatusInfo.createdAt > onlineWolkCutOff,
          }));

          if (this.$store.getters['myAccount/isNonAdminUser']) {
            return;
          }

          this.setRetourVars();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setRetourVars() {
      const retourStatuses = this.wolks
        .filter((w) => w.retour && w.retour.status && w.retourEnabled)
        .map((w) => w.retour.status);
      this.showSendBtn = retourStatuses.some((rs) => rs === 'exchange' || rs === 'retour') // -> filter for orgs that have config enabled
        && (this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdminUser']);

      this.firstName = this.$store.state.myAccount.firstName;
      this.lastName = this.$store.state.myAccount.lastName;
      this.email = this.$store.state.myAccount.email;
      this.organizationId = this.$store.state.myAccount.organizationId;
    },
    deleteWolk(item) {
      console.log(`Should delete wolk ${item.sleeve.id} now.`);
    },
    patchWolkRetours(retours) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/retour`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: retours,
      });
    },
    closeDialog() {
      this.dialog = false;
      this.dialogScreen = 'selectWolks';
    },
    sendToOrderSystem(wolkIds) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/retour/order`;
      return axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'post',
        data: { wolkIds },
      });
    },
    createRetourParcel(wolkIds) {
      const parcelItems = [];
      for (let i = 0; i < wolkIds.length; i += 1) {
        const wolkId = wolkIds[i];
        const [wolk] = this.wolks.filter((w) => w._id === wolkId);
        const type = wolk?.type;
        const sleevePublicId = wolk?.metaData?.sleevePublicId;

        if (!type || !sleevePublicId) {
          this.$store.commit('app/snackbarText', {
            text: 'Kon geen retour label aanmaken: Wolk heeft geen type of WolkNr',
            visible: true,
          });
          throw new Error('Can not create retour label: Wolk has no type or WolkNumber');
        }

        parcelItems.push({
          hs_code: type === 'Shorts' ? 62046110 : 42033000,
          weight: 0.350,
          quantity: 1,
          description: type,
          properties: {
            wolkNr: sleevePublicId,
          },
          value: 25,
        });
      }

      const body = {
        parcel: {
          // sender
          from_name: `${this.firstName} ${this.lastName}`,
          from_email: this.email,
          from_address_1: this.fromStreet,
          from_house_number: this.fromHouseNumber,
          from_city: this.fromCity,
          from_postal_code: this.fromPostalCode,
          from_country: this.fromCountry,

          // destination = Wolk Airbag
          name: 'Wolk Airbag',
          address: 'Anna van Hannoverstraat 4', // street + Nr
          city: 'Den Haag',
          postal_code: '2595BJ',
          country: 'NL',
          shipment: {
            id: 1529, // PostNL
          },
          is_return: true,
          request_label: true,
          parcel_items: parcelItems,
        },

      };

      const url = `${config.VUE_APP_API_BASE_URL}/wolks/parcel`;
      return axios({
        url,
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: body,
      }).then((response) => {
        this.$store.commit('app/snackbarSuccess', {
          text: 'Retour label aangemaakt.',
          visible: true,
        });
        return response;
      });
    },
    async downloadLabelPdf(wolkId) {
      const url = `${config.VUE_APP_API_BASE_URL}/wolks/${wolkId}/retour/download`;
      return axios({
        url,
        responseType: 'arraybuffer',
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      });
    },
  },
};
</script>
